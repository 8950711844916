import InfoIcon from "@mui/icons-material/Info";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getSupportUrlForLanguage } from "../../../utils/utils";
import { LeftMenuList } from "../../Body";

function HelpCenterOption() {
  const [t, i18n] = useTranslation();

  return (
    <LeftMenuList>
      <ListItemButton
        component="a"
        target="_blank"
        rel="noreferrer noopener"
        href={getSupportUrlForLanguage(i18n.language)}
      >
        <ListItemIcon>
          <InfoIcon color="primary" />
        </ListItemIcon>
        <ListItemText primary={t("helpcenter")} />
      </ListItemButton>
    </LeftMenuList>
  );
}

export default HelpCenterOption;
