import { Business, Edit, Language, Person } from "@mui/icons-material";
import BuildIcon from "@mui/icons-material/Build";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { includes } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { routes } from "../../../Routes";
import { useAuth0 } from "../../../auth0/ReactAuth0Wrapper";
import { allCustomersId } from "../../../constants/constants";
import {
  SubMenu,
  configurationMenu,
  contentMenu,
  customerSettingsMenu,
  operationMenu,
  translationsMenu,
  usersMenu,
} from "../../../constants/menus";
import { stateCategoryMap } from "../../../constants/state";
import { navigationSections } from "../../../constants/subjects";
import { useActivatedServices } from "../../../hooks/hooks";
import { useGlobalState } from "../../../state";
import { ServiceTypeLowerCase } from "../../../types/services";
import { Section, SubjectLabel } from "../../../types/subjects";
import { isPermitted } from "../../../utils/authorization";
import { LeftMenuList } from "../../Body";
import ConfigurationMenu from "./ConfigurationMenu";
import ContentMenu from "./ContentMenu";
import CustomerSettingsMenu from "./CustomerSettingsMenu";
import OperationMenu from "./OperationMenu";
import TranslationsMenu from "./TranslationsMenu";
import UsersMenu from "./UsersMenu";

interface NavigationListProps {
  subject: SubjectLabel | "";
}

function NavigationList(props: NavigationListProps) {
  const [t] = useTranslation();
  const { user } = useAuth0();
  const [roleLevels] = useGlobalState(stateCategoryMap.ROLE_LEVELS);
  const [globalCustomer] = useGlobalState(stateCategoryMap.CUSTOMER);
  const { activatedServices } = useActivatedServices();
  const location = useLocation();
  const { subject } = props;
  const [open, setOpen] = useState<Section | "none">("none");
  const [configurationHasItems, setConfigurationHasItems] =
    useState<boolean>(false);
  const [operationHasItems, setOperationHasItems] = useState<boolean>(false);
  const [usersHasItems, setUsersHasItems] = useState<boolean>(false);
  const [customerSettingsHasItems, setCustomerSettingsHasItems] =
    useState<boolean>(false);
  const [contentHasItems, setContentHasItems] = useState<boolean>(false);
  const [translationsHasItems, setTranslationsHasItems] =
    useState<boolean>(false);

  useEffect(() => {
    const overviewSites = [
      routes.access.root,
      routes.app.root,
      routes.assist.root,
      routes.butler.root,
      routes.gates.root,
      routes.general.root,
      routes.library_app.root,
      routes.people_counter.root,
      routes.profile,
    ];
    if (overviewSites.includes(location.pathname)) {
      setOpen("");
    }
    navigationSections.forEach((s: Section) => {
      if (location.pathname.includes(s)) {
        setOpen(s);
      }
    });
    filterMenu();
  }, [location.pathname]);

  useEffect(() => {
    filterMenu();
  }, [roleLevels, subject, activatedServices]);

  function permittedAndVisibleForCustomer(
    user: any,
    roleLevels: any,
    subMenu: SubMenu
  ) {
    if (
      (globalCustomer.id !== allCustomersId &&
        subMenu.excludeFromCustomerLayer) ||
      (globalCustomer.id === allCustomersId && subMenu.excludeFromMetaLayer)
    ) {
      return false;
    }
    return isPermitted(user, roleLevels, subMenu.visibleFor);
  }

  function filterMenu() {
    const sectionsWithItems: string[] = [];
    const configurationItems =
      (configurationMenu[subject] &&
        configurationMenu[subject].some((subMenu) => {
          return permittedAndVisibleForCustomer(user, roleLevels, subMenu);
        })) ||
      false;
    const operationItems =
      (operationMenu[subject] &&
        operationMenu[subject].some((subMenu) => {
          return permittedAndVisibleForCustomer(user, roleLevels, subMenu);
        })) ||
      false;
    const usersItems =
      (usersMenu[subject] &&
        usersMenu[subject].some((subMenu) => {
          return permittedAndVisibleForCustomer(user, roleLevels, subMenu);
        })) ||
      false;
    const customerSettingsItems = hasContentItems(
      customerSettingsMenu[subject],
      activatedServices
    );
    const contentItems = hasContentItems(
      contentMenu[subject],
      activatedServices
    );
    const translationsItems =
      (translationsMenu[subject] &&
        translationsMenu[subject].some((subMenu) => {
          if (
            globalCustomer.id !== allCustomersId &&
            subMenu.excludeFromCustomerLayer
          )
            return false;
          return isPermitted(user, roleLevels, subMenu.visibleFor);
        })) ||
      false;

    if (configurationItems) sectionsWithItems.push("configuration");
    if (operationItems) sectionsWithItems.push("operation");
    if (usersItems) sectionsWithItems.push("users");
    if (customerSettingsItems) sectionsWithItems.push("customerSettings");
    if (contentItems) sectionsWithItems.push("content");
    if (translationsItems) sectionsWithItems.push("translations");
    if (sectionsWithItems.length === 1) setOpen(sectionsWithItems[0]);
    setConfigurationHasItems(configurationItems);
    setOperationHasItems(operationItems);
    setUsersHasItems(usersItems);
    setCustomerSettingsHasItems(customerSettingsItems);
    setContentHasItems(contentItems);
    setTranslationsHasItems(translationsItems);
  }

  function hasContentItems(
    menu: SubMenu[],
    services: ServiceTypeLowerCase[]
  ): boolean {
    let filteredMenu: SubMenu[] = [];
    if (!menu) {
      return false;
    }

    if (globalCustomer.id === allCustomersId) {
      filteredMenu = menu;
    } else {
      menu.forEach((item: SubMenu) => {
        if (includes(services, item.scope)) {
          filteredMenu.push(item);
        }
      });
    }

    return (
      (filteredMenu &&
        filteredMenu.some((subMenu) => {
          return permittedAndVisibleForCustomer(user, roleLevels, subMenu);
        })) ||
      false
    );
  }

  function openList(section: string): boolean {
    return open === section;
  }

  if (!subject) {
    return null;
  }

  return (
    <LeftMenuList>
      {configurationHasItems && (
        <>
          <ListItemButton
            onClick={() =>
              open === "configuration"
                ? setOpen("none")
                : setOpen("configuration")
            }
          >
            <ListItemIcon>
              <SettingsIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("configuration.title")} />
            {!openList("configuration") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openList("configuration")} timeout="auto" unmountOnExit>
            <ConfigurationMenu subject={subject} />
          </Collapse>
        </>
      )}
      {customerSettingsHasItems && (
        <>
          <ListItemButton
            onClick={() =>
              open === "customer_settings"
                ? setOpen("none")
                : setOpen("customer_settings")
            }
          >
            <ListItemIcon>
              <Business color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("customer_settings.title")} />
            {!openList("customer_settings") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={openList("customer_settings")}
            timeout="auto"
            unmountOnExit
          >
            <CustomerSettingsMenu
              subject={subject}
              activatedServices={activatedServices}
            />
          </Collapse>
        </>
      )}
      {usersHasItems && (
        <>
          <ListItemButton
            onClick={() =>
              open === "users" ? setOpen("none") : setOpen("users")
            }
          >
            <ListItemIcon>
              <Person color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("users.title")} />
            {!openList("users") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openList("users")} timeout="auto" unmountOnExit>
            <UsersMenu subject={subject} />
          </Collapse>
        </>
      )}
      {contentHasItems && (
        <>
          <ListItemButton
            onClick={() =>
              open === "content" ? setOpen("none") : setOpen("content")
            }
          >
            <ListItemIcon>
              <Edit color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("content.title")} />
            {!openList("content") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openList("content")} timeout="auto" unmountOnExit>
            <ContentMenu
              subject={subject}
              activatedServices={activatedServices}
            />
          </Collapse>
        </>
      )}
      {translationsHasItems && (
        <>
          <ListItemButton
            onClick={() =>
              open === "translation_management"
                ? setOpen("none")
                : setOpen("translation_management")
            }
          >
            <ListItemIcon>
              <Language color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("translations.title")} />
            {!openList("translation_management") ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItemButton>
          <Collapse
            in={openList("translation_management")}
            timeout="auto"
            unmountOnExit
          >
            <TranslationsMenu subject={subject} />
          </Collapse>
        </>
      )}
      {operationHasItems && (
        <>
          <ListItemButton
            onClick={() =>
              open === "operation" ? setOpen("none") : setOpen("operation")
            }
          >
            <ListItemIcon>
              <BuildIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={t("operation.title")} />
            {!openList("operation") ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open === "operation"} timeout="auto" unmountOnExit>
            <OperationMenu subject={subject} />
          </Collapse>
        </>
      )}
    </LeftMenuList>
  );
}

export default NavigationList;
