import { KeyValueMap } from "../types/interfaces";
import { ServiceTypeLowerCase } from "../types/services";
import { serviceKeysMapLowerCase } from "./services";

const greenHex = "#a5d6a7";
const orangeHex = "#ffcd80";
const redHex = "#ef9a9a";
const blueHex = "#81d3fa";
const purpleHex = "#be98e6";
const darkGreenHex = "#7ab8b1";
const darkBlueHex = "#bfc7ea";
const yellowHex = "#efeca7";
const pinkHex = "#ffb6ce";
const greyHex = "#e0e0e0";

const greenBorderHex = "#388e3b";
const orangeBorderHex = "#f57c00";
const redBorderHex = "#d32f2f";
const blueBorderHex = "#0587d1";
const purpleBorderHex = "#631ab2";
const darkGreenBorderHex = "#005c50";
const darkBlueBorderHex = "#08389b";
const yellowBorderHex = "#bcb33f";
const pinkBorderHex = "#c50054";
const greyBorderHex = "#616161";

export const colorList = [
  greenHex,
  orangeHex,
  redHex,
  blueHex,
  purpleHex,
  darkGreenHex,
  darkBlueHex,
  yellowHex,
  pinkHex,
  greyHex,
];

export const eventStateColorMap: KeyValueMap = {
  [greenHex]: greenBorderHex,
  [orangeHex]: orangeBorderHex,
  [redHex]: redBorderHex,
  [blueHex]: blueBorderHex,
  [purpleHex]: purpleBorderHex,
  [darkGreenHex]: darkGreenBorderHex,
  [darkBlueHex]: darkBlueBorderHex,
  [yellowHex]: yellowBorderHex,
  [pinkHex]: pinkBorderHex,
  [greyHex]: greyBorderHex,
};

export const tableIds: KeyValueMap = {
  ADD: "add",
  ARROWS: "arrows",
  BARCODE: "barcode",
  BINKEY: "binkey",
  BOX: "box",
  BRANCHES: "branches",
  BRANCHID: "branchid",
  BRANCHNAME: "branchname",
  BUTTON: "button",
  COUNTRY: "country",
  CREATORS: "creators",
  DATE: "date",
  DAY: "day",
  DELETE: "delete",
  DESCRIPTION: "desc",
  EDIT: "edit",
  EDITOR: "editor",
  EMAIL: "email",
  FILENAME: "filename",
  GATEID: "gateid",
  GATELOCATION: "gatelocation",
  HOUR: "hour",
  ICON: "icon",
  ID: "id",
  IN: "in",
  ISO: "iso",
  KEY: "key",
  LANGUAGE: "language",
  LOGIN_WITHOUT_PIN: "login_without_pin",
  NAME: "name",
  ORDER: "order",
  OUT: "out",
  PARTNERID: "partnerid",
  PATRONCARDNUMBER: "patroncardnumber",
  PLAYER: "player",
  PRODUCTS: "products",
  ROLES: "roles",
  RPORTID: "rportid",
  SHOWN_FROM: "shownfrom",
  SHOWN_TO: "shownto",
  SIZE: "size",
  STATUS: "status",
  TIMESTAMP: "timestamp",
  TITLE: "title",
  TRANSLATION: "translation",
  TYPE: "type",
  UNITTYPE: "unittype",
  USER: "user",
  VALUE: "value",
  VERIFIED: "verified",
};

export const environments = {
  LOCAL: "development",
  DEV: "dev",
  STAGING: "staging",
  PROD: "production",
};

export const CICERO: string = "cicero";
export const CICERO_DEICHMAN: string = "cicero_deichman";
export const BOOKIT: string = "bookit";
export const BIBLIOFIL: string = "bibliofil";
export const FBS: string = "fbs";
export const QURIA: string = "quria";
export const DEICHMAN: string = "deichman";
export const MIKROMARC: string = "mikromarc";
export const OPEN_LIBRARY_API: string = "open_library_api";

export const customId = "custom";
export const showAllId = "all";
export const accessDenied = "access_denied";
export const patronBlocked = "patron_blocked";
export const userIsInvalid = "user_is_invalid";
export const userAccessDenied = "user_access_denied";
export const userAccessGranted = "user_access_granted";
export const userIsAgeRestricted = "user_is_age_restricted";
export const wrongUsernameOrPassword = "wrong_username_or_password";

export const allCustomersId = "allCustomers";
export const customerNotloaded = "notLoaded";

export const MaxMajorValue: number = 65535;
export const MaxMinorValue: number = 65535;

export const i18nLanguageKeyMap: KeyValueMap = {
  da: "dan",
  no: "nno",
  nb: "nob",
  sw: "swe",
  en: "eng",
};

export const acceptNone = "acceptNone";
export const acceptAll = "acceptAll";

export const urlProtocolShort = "http:";
export const urlProtocol = "http://";
export const urlProtocolSecure = "https://";

export interface DateInterval {
  start: string;
  end: string;
}

export type UnitsOfTime = "day" | "week" | "month" | "year";
export type DateCompareType = "previous_year" | "previous_period";

export const unitsOfTime: Record<UnitsOfTime, UnitsOfTime> = {
  day: "day",
  week: "week",
  month: "month",
  year: "year",
};

export const dateCompareType: Record<DateCompareType, DateCompareType> = {
  previous_year: "previous_year",
  previous_period: "previous_period",
};

export const systemEmailDomain = "@redia.dk";

export const serviceList: ServiceTypeLowerCase[] = [
  serviceKeysMapLowerCase.ANNOUNCEMENTS,
  serviceKeysMapLowerCase.SURVEYS,
];
